import React from 'react'
import {
    Box,
    Button,
    Center,
    FormControl,
    FormLabel,
    Heading,
    Input,  
    FormErrorMessage,
    FormHelperText,
    SimpleGrid,
    Textarea
  } from "@chakra-ui/react"

  // TODO wire up contact to send email
  // TODO style send buttons (all buttons)
export default function ContactPage() {

    const onSendClick = (e)=>{
        console.log('send clicked')
    }

    return (
        <Center m="3rem">
        <Box alignContent="center"  w={["md", "lg", "xl"]} flexDir="column">
        <Heading mb="3rem">Contact Us!</Heading>
        <SimpleGrid columns={1} spacing={10}>
        <form onSubmit={onSendClick}>
            <FormControl id="name" isRequired>
                <FormLabel>Name</FormLabel>
                <Input type="name" />
            </FormControl>
            <FormControl id="email" isRequired>
                <FormLabel>Email address</FormLabel>
                <Input type="email" />
                <FormHelperText>We'll never share your email.</FormHelperText>
            </FormControl>
            <FormControl id="message" isRequired>
                <FormLabel>Message</FormLabel>
                <Textarea placeholder="Tell us something..." />
            </FormControl>
            <FormControl id="submit">
                <Button colorScheme="teal" variant="solid"  type="submit" mt="1rem">
                    Send
                </Button>
            </FormControl>
            </form>
        </SimpleGrid>
        </Box>
        </Center>
    )
}
