import {FETCH_ALL_PRODUCTS, FETCH_PRODUCT, SELECT_PRODUCT_BY_HANDLE} from '../actions/types'

const initState = {
    products: [],
    selectedProduct: null,
}

const storeReducer = (state=initState, action)=>{
    switch(action.type){
        case FETCH_ALL_PRODUCTS:
            return{
                ...state,
                products: action.payload,
                selectedProduct: null
            }
        case SELECT_PRODUCT_BY_HANDLE:
            return{
                ...state,
                products: action.payload.products,
                selectedProduct: action.payload.selectedProduct
            }
        case FETCH_PRODUCT:
            break
        default:
            return state;
    }
}

export default storeReducer
//TODO Check on mapStateToProps https://redux.js.org/recipes/structuring-reducers/using-combinereducers/