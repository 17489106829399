import React from 'react'
import {Link} from 'react-router-dom'
import {Flex, Button, Image, Text, Box, Grid, HStack, Icon, Spacer, VStack, Divider} from '@chakra-ui/react'

const Footer = () => {
    return (
        <VStack bgGradient="linear(260deg, rgba(109, 108, 135, 1) 27%, rgba(188, 151, 177, 1) 70%)" height="6rem" bottom="0" position="absolute" width="100%" > 
            <Divider h="2rem"/>
            <HStack h="3rem" w="100%" p="2rem">
                <Spacer/>
                <Text color="white">©2021 Execuballs, LLC powered by Shopify</Text>
            </HStack>
            
      </VStack>  
    )
}

export default Footer 