import React from 'react'
import Iframe from 'react-iframe'
import {AspectRatio, Box, Center} from '@chakra-ui/react'

export default function PressPage() {
    return (
        <AspectRatio ratio={1}>
            <Iframe url="https://cdn.lightwidget.com/widgets/088a4d724ba15d2eadcfaa3d4e4e7e4c.html"
                    width="800px"
                    height="800px"
                    scrolling="no"
                    id="ig_feed"
                    className="lightwidget-widget"/>
        </AspectRatio>)
}

/*
<!-- LightWidget WIDGET --><script src="https://cdn.lightwidget.com/widgets/lightwidget.js"></script><iframe src="//lightwidget.com/widgets/088a4d724ba15d2eadcfaa3d4e4e7e4c.html" scrolling="no" allowtransparency="true" class="lightwidget-widget" style="width:100%;border:0;overflow:hidden;"></iframe>
<!-- LightWidget WIDGET --><script src="https://cdn.lightwidget.com/widgets/lightwidget.js"></script><iframe src="https://cdn.lightwidget.com/widgets/088a4d724ba15d2eadcfaa3d4e4e7e4c.html" scrolling="no" allowtransparency="true" class="lightwidget-widget" style="width:100%;border:0;overflow:hidden;"></iframe>
*/