import React, {useEffect} from 'react'
import {connect} from 'react-redux'
import CartItem from './CartItem'
import {toggleCart, initCart} from '../actions/checkoutActions'
import {
    Drawer,
    DrawerBody,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
    Button,
    VStack,
    Text,
    Grid,
    Flex,
    Image,
    Link,
    Box
  } from "@chakra-ui/react"
import { CloseIcon } from '@chakra-ui/icons';

// TODO quantaties for line items

const Cart = (props)=> {

    useEffect(()=>{
        props.initCart();
      },[])

      
        const closeCart = ()=>{
            props.toggleCart(false);
        }

        const onCheckoutClick = ()=>{
          window.location.href = props.checkout.webUrl;
        }

        return (
            <>
            <Drawer
              isOpen={props.cartOpen}
              placement="right"
              onClose={closeCart}
              size="sm"
            >
              <DrawerOverlay>
                <DrawerContent backgroundColor="#f5f5f5">
                  <DrawerCloseButton />
                  <DrawerHeader>Shopping Cart</DrawerHeader>
                  <DrawerBody>
                    <Box borderRadius=".5rem" minH="100%" backgroundColor="white" p=".25rem">
                      {
                        props.checkout.lineItems?.length ? props.checkout.lineItems.map((item)=>{
                            return <CartItem key={item.id} lineItem={item}/> 
                        }): null
                      }
                    </Box>
                  </DrawerBody>
                  <DrawerFooter>
                    <VStack w="100%">
                    <Text textAlign="right" w="100%" mb="1rem">total ${props.checkout.totalPrice}</Text>
                      <Button colorScheme="orange" onClick={onCheckoutClick} w="100%">Chekcout</Button>
                      </VStack>
              </DrawerFooter>
                </DrawerContent>
              </DrawerOverlay>
            </Drawer>
            </>
        )
    }


const mapStateToProps = (state)=>{
    return {
        cartOpen: state.checkoutReducer.cartOpen,
        checkout:  state.checkoutReducer.checkout,
     }
 }
 
 export default connect(mapStateToProps, {toggleCart, initCart})(Cart)
