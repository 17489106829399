import React from 'react'
import {connect} from 'react-redux'
import {removeProductFromCart} from '../actions/checkoutActions'
import {
    Button,
    Text,
    Grid,
    Flex,
    Image,
    Link,
    Box
  } from "@chakra-ui/react"
  import { CloseIcon } from '@chakra-ui/icons';

const CartItem = (props)=> {

        const removeLineItem = (itemId)=>{
            props.removeProductFromCart(props.lineItem.id);
        }

        return (
            <Grid templateColumns="repeat(4, 1fr)" gap={1} key={props.id} mb="1rem" mt="1rem">
                <Flex alignItems="center" justifyContent="center">
                    <CloseIcon cursor="pointer" onClick={removeLineItem} />
                </Flex>
                <Flex alignItems="center" justifyContent="center">
                    <Image src={props.lineItem.variant.image.src}/>
                </Flex>
                <Flex alignItems="center" justifyContent="center">
                    <Text>{props.lineItem.title}</Text>
                </Flex>
                <Flex alignItems="center" justifyContent="center">
                    <Text>${props.lineItem.variant.price}</Text>
                </Flex>                
            </Grid>
        )
    }


 export default connect(null, {removeProductFromCart})(CartItem)
