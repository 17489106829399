import './App.css';
import {Provider as ReduxProvider} from 'react-redux'
import { ChakraProvider } from '@chakra-ui/react'
import AppRouter from './routes/AppRouter'
import reduxStore from './components/reduxStore'
import React from 'react'
import "@fontsource/roboto/400.css"
import "@fontsource/roboto/700.css"
import theme from './assets/theme'

 const App = ()=> {
    return (
      <ReduxProvider store={reduxStore}>
      <ChakraProvider theme={theme}>
      <div className="App">
       <AppRouter/>
      </div>
      </ChakraProvider>
      </ReduxProvider>
    )
}

export default App
