import  { ADD_PRODUCT_TO_CART, FETCH_CART, INIT_CART, REMOVE_PRODUCT_FROM_CART, TOGGLE_CART}from './types'

const CHECKOUT_ID = 'checkout-id';

const addProductToCart =  (variantId, quantity=1)=> async (dispatch, getState, shopifyClient)=>{
    const lineItemsToAdd = [
        {
            variantId,
            quantity
        }
    ]
    try{
        const checkout = await shopifyClient.checkout.addLineItems(localStorage[CHECKOUT_ID], lineItemsToAdd);
        dispatch({
            type: ADD_PRODUCT_TO_CART,
            payload: checkout
        })
    }catch(error){
        console.log(error);
    }
}

const fetchCart = (checkoutId)=> async (dispatch, getState, shopifyClient)=>{
    try{
        const checkout = await shopifyClient.checkout.fetch(checkoutId);
        dispatch({
            type: FETCH_CART,
            payload: { checkout }
        })
    }catch(error){
        console.log(error) 
    }
}


const initCart = ()=> async (dispatch, getState, shopifyClient)=>{
    // check if localstorage has a checkout-id
        if(localStorage[CHECKOUT_ID]){
            try{
                const checkout = await shopifyClient.checkout.fetch(localStorage[CHECKOUT_ID]);
                dispatch({
                    type: FETCH_CART,
                    payload: { checkout }
                })
            }catch(error){
                console.log(error) 
            }
        }else{
            const checkout = await shopifyClient.checkout.create();
            localStorage.setItem(CHECKOUT_ID, checkout.id);
            dispatch({
                type: FETCH_CART,
                payload: { checkout }
            })
        }
}

const removeProductFromCart =  (itemIDs)=> async (dispatch, getState, shopifyClient)=>{

    try{
        const checkout = await shopifyClient.checkout.removeLineItems(localStorage[CHECKOUT_ID],  itemIDs)
        dispatch({
            type: REMOVE_PRODUCT_FROM_CART,
            payload: checkout
        })
    }catch(error){
        console.log(error);
    }
}

const toggleCart = (doOpen=true)=>(dispatch)=> {
    dispatch({
        type: TOGGLE_CART,
        payload: doOpen
    })
}


export {addProductToCart, fetchCart, initCart, removeProductFromCart, toggleCart}