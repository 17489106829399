import React from 'react'
import {connect} from 'react-redux'
import { addProductToCart} from '../actions/checkoutActions'
import {Box,  Grid, Image, Text, Button,  Heading, Flex, SimpleGrid, Badge, Center} from '@chakra-ui/react'
// import  { MdChevronLeft, MdChevronRight } from 'react-icons/md'

const ProductCard = (props)=> {

       let  parsedDescription = {};

       try{
        parsedDescription = JSON.parse(props.description);
        console.log(parsedDescription.description)
       }catch(error){
        console.log(parsedDescription)
       }
                
        const onAddProductClick=()=>{
            props.addProductToCart(props.variants[0].id);
        }
        
        return (
                <Center>
                    <Grid id="prodcard_grid" templateColumns={["repeat(1, 1fr)","repeat(2, 1fr)"]} m="3rem" gap={6}>
                        <Flex id="flex_imaage" alignItems="center" justifyContent="center"> 
                            <Image w="100%" maxW="60vh" minW="20vh" src={props.images[0].src} />
                        </Flex>
                        <Flex id="flex_details" flexDir="column" alignItems="center" justifyContent="center" gap={6}>
                             <Badge variant="outline" colorScheme="orange" mt="1rem" hidden={parsedDescription.limited==='false'}>LIMITED EDITION</Badge>
                            <Heading>{props.title}</Heading>
                            <Text fontWeight="bold">${props.variants[0].price}</Text>
                            <Text textAlign="justify">{parsedDescription.description}</Text>
                            <Box>
                            <SimpleGrid columns={2} spacing={1} mt="1rem">
                                <Text textAlign="right">color:</Text><Text textAlign="left">{parsedDescription.color}</Text>
                                <Text textAlign="right">stitch color:</Text><Text textAlign="left">{parsedDescription.stitchColor}</Text>
                                <Text textAlign="right">usage:</Text><Text textAlign="left">{parsedDescription.usage}</Text>
                                <Text textAlign="right">size:</Text><Text textAlign="left">{parsedDescription.size}</Text>
                            </SimpleGrid>
                            <Text>{parsedDescription.note}</Text>
                            </Box>                            
                            <Button _hover={{opacity: '70%'}} mt="3rem" w="10rem" onClick={onAddProductClick}>Add To Cart</Button>
                        </Flex>
                    </Grid>
                 </Center>
        )
    
}

const mapDispatchToProps = (dispatch) => {
    return {
        addProductToCart: (variantId) => dispatch(addProductToCart(variantId)),
    }
  }

export default connect(null, mapDispatchToProps)(ProductCard)
