import React, {useEffect} from 'react'
import {connect} from 'react-redux'
import {Container, Box, Center, HStack, IconButton, Button} from '@chakra-ui/react'
import  { MdChevronLeft, MdChevronRight } from 'react-icons/md'
import { PropTypes } from 'prop-types';
import { selectProductByHandle} from '../actions/productActions'
import ProductCard from '../components/ProductCard'
import { withRouter } from 'react-router-dom';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// TODO arrows for swipe

const ProductPage = (props)=> {

    useEffect(()=>{
        const productHandle = props.history.location.pathname.replace('/products/','');
        props.selectProductByHandle(productHandle);
      },[props])


        const onTransitionEnd =(selectedIndex)=>{
            const product = props.products[selectedIndex];
           props.history.push(`/products/${product.handle}`);
        }

        // render nothing if no selectedProduct
        if(!props.selectedProduct){
            return(<div></div>)
        }

        const settings = {
            dots: false,
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            nextArrow: <MdChevronRight opacity="40%" _hover={{ opacity: '100%'}} fill="rgb(188 151 177)" zIndex="50"/>,
            prevArrow: <MdChevronLeft  opacity="40%" _hover={{ opacity: '100%'}} fill="rgb(188 151 177)"  zIndex="50"/>
          };

        return (
            
            <Box flexDir="row" position="relative">
                <Slider {...settings} afterChange={onTransitionEnd} initialSlide={props.selectedProduct.index} zIndex="10" >
                    {props.products.map((product) => {
                        return <ProductCard key={product.id} {...product}/>
                    })
                    }
                </Slider >
            </Box>
        )
}

ProductPage.propTypes = {
    products: PropTypes.array.isRequired,
    selectedProduct: PropTypes.object,
  };

const mapStateToProps = (state)=>{
    return {
         products: state.storeReducer.products,
         selectedProduct: state.storeReducer.selectedProduct
     }
 }

export default connect(mapStateToProps, {selectProductByHandle})(withRouter(ProductPage));
 