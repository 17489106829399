import React from 'react'
import {Router, Route, Switch} from 'react-router-dom'

import HomePage from '../pages/HomePage'
import ProductPage from '../pages/ProductPage';
import Cart from '../components/Cart'
import NavBar from '../components/NavBar'
import Footer from '../components/Footer';
import { createBrowserHistory } from "history";
import {Flex, Box} from '@chakra-ui/react'
import FAQ from '../pages/FAQPage';
import Press from '../pages/PressPage'
import FAQPage from '../pages/FAQPage';
import PressPage from '../pages/PressPage';
import ContactPage from '../pages/ContactPage';

const history = createBrowserHistory();

export const AppRouter = () => {
    return (
      <Box  position="relative" minH="100vh">
        <Flex flexDir="column" paddingBottom="6rem">
        <Router history={history}>
        <NavBar />
        <Cart />
      <Switch>
        <Route path="/faq" exact={true}>
          <FAQPage/>
        </Route>
        <Route path="/press" exact={true}>
          <PressPage/>
        </Route>
        <Route path="/contact" exact={true}>
         <ContactPage />
        </Route>
        <Route path="/products/:handle">
          <ProductPage/>
        </Route>
        <Route path="/">
          <HomePage/>
        </Route>
        </Switch>
        <Footer />
      </Router>
      </Flex>
      </Box>
    )
}

export default AppRouter;
