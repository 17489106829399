import {createStore, applyMiddleware, compose} from 'redux'
import thunk from 'redux-thunk'
import rootReducer from '../reducers'
import Client from 'shopify-buy'

const shopifyClient = Client.buildClient({
    domain: 'execuballs.myshopify.com',//process.env.SHOPIFY_DOMAIN,
    storefrontAccessToken:  'e17c46af7e1a31b6d48689730696fa52',//process.env.SHOPIFY_API_KEY
});


const middleWare = [thunk.withExtraArgument(shopifyClient)];

const reduxStore = createStore(rootReducer, {}, compose(applyMiddleware(...middleWare),), )

export default reduxStore;

// window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()