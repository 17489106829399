import {FETCH_ALL_PRODUCTS, SELECT_PRODUCT_BY_HANDLE} from './types'


const productsChache = {updtaedAt: new Date('2020-1-1'), products:[], timeoutInMilli:400000}

const  loadProducts = (shopifyClient)=> {
     return new Promise((resolve, reject) => {
        const  now = new Date();
        if((now - productsChache.updtaedAt < productsChache.timeoutInMilli)){
            resolve(true);
        }else{
                shopifyClient.product.fetchAll().then((result)=> {
                    productsChache.products = result;
                    productsChache.updtaedAt = new Date();
                    let productIndex = 0;
                    productsChache.products.forEach(element => {
                        element.index = productIndex++;
                    });
                    resolve(true);
                })
            }
        }
    )
}
  
const fetchAllProducts = ()=> (dispatch, getState, shopifyClient)=>{
    loadProducts(shopifyClient).then(result=>{
        dispatch({
            type: FETCH_ALL_PRODUCTS,
            payload: productsChache.products
        })
    })
}

const selectProductByHandle = (handle)=> (dispatch, getState, shopifyClient)=>{
    loadProducts(shopifyClient).then(result=>{
        // find selected product by handle
        const selectedIndex = productsChache.products.findIndex(product => product.handle === handle);   
        dispatch({
            type: SELECT_PRODUCT_BY_HANDLE,
            payload: {
                products: productsChache.products,
                selectedProduct: productsChache.products[selectedIndex]
            }
        })
    })
}


export {fetchAllProducts, selectProductByHandle}