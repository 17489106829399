import React, { useState } from 'react'
import {Link} from 'react-router-dom'
import {Box, Text, Image, Skeleton, SimpleGrid} from '@chakra-ui/react'

const ProductTile = (props)=>{

  const [imageLoaded, setImageLoaded] = useState(false);

  const  handleImageLoaded = ()=> {
    setImageLoaded(true)
  }

  const handleImageErrored = ()=> {
    // TODO load default image
  }

    return (
        <Link key={props.product.id} to={`/products/${props.product.handle}`}>
            <Box _hover={{ opacity: '80%'}} textAlign="center" visibility={imageLoaded ? "visible" : "hidden"}>
                 <Skeleton startColor="pink" endColor="orange" h="100%" isLoaded w="100%">
                     <Image onError={handleImageErrored.bind(this)}  onLoad={handleImageLoaded.bind(this)} src={props.product.images[0].src} />
                 </Skeleton>
                 <SimpleGrid columns={[1, 2]} p=".5rem">
                  <Text fontWeight="bold">{props.product.title}</Text>
                  <Text>${props.product.variants[0].price}</Text>
                 </SimpleGrid>
             </Box>
         </Link>
        )
}

export default ProductTile;

