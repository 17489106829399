import React from 'react'
import {
    Heading,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    Box,
    Center,
    Link,
    Text
  } from "@chakra-ui/react"

export default function FAQPage() {
    return (
        <Center m="3rem">
            <Box alignContent="center"  w={["md", "lg", "xl"]} flexDir="column">
            <Heading mb="3rem">FAQ</Heading>
            <Accordion colorScheme="pink" w="100%" allowToggle>
            <AccordionItem>
                <h2>
                <AccordionButton  _expanded={{ bg: "rgba(109, 108, 135, 1)", color: "white" }}>
                    <Box flex="1" textAlign="left">
                    Where are Execuballs made?
                    </Box>
                    <AccordionIcon />
                </AccordionButton>
                </h2>
                <AccordionPanel m="1rem" textAlign="justify">
                Execuballs are made in Los Angeles, California USA. Each Execuballs stability ball cover is made to order. This allows us to carry myriad styles and even create custom designs.
                </AccordionPanel>
            </AccordionItem>

            <AccordionItem>
                <h2>
                <AccordionButton  _expanded={{ bg:  "rgba(109, 108, 135, 1)", color: "white" }}>
                    <Box flex="1" textAlign="left">
                    How are Execuballs made?
                    </Box>
                    <AccordionIcon />
                </AccordionButton>
                </h2>
                <AccordionPanel m="1rem" textAlign="justify">
                Execuballs stability ball covers are made to order, by hand, not mass produced. They are built with quality in mind to provide you a great looking product that will spark joy for years to come. The structure of the ball is constantly under varying degrees of pressure so we use a reinforced seam for long lasting integrity.
ô là! Là!
                </AccordionPanel>
            </AccordionItem>

            <AccordionItem>
                <h2>
                <AccordionButton  _expanded={{ bg:  "rgba(109, 108, 135, 1)", color: "white" }}>
                    <Box flex="1" textAlign="left">
                    Is the stability/yoga ball included?
                    </Box>
                    <AccordionIcon />
                </AccordionButton>
                </h2>
                <AccordionPanel m="1rem" textAlign="justify">
                No. At this time we do not sell stability balls with the Execuballs cover. 
                </AccordionPanel>
            </AccordionItem>

            <AccordionItem>
                <h2>
                <AccordionButton  _expanded={{ bg:  "rgba(109, 108, 135, 1)", color: "white" }}>
                    <Box flex="1" textAlign="left">
                    Can I use these in my home gym?
                    </Box>
                    <AccordionIcon />
                </AccordionButton>
                </h2>
                <AccordionPanel m="1rem" textAlign="justify">
                Sure! Most of the fabrics are durable enough for gym use. Sometimes we see a fabric that is just gorgeous, maybe not as hardy, but we just can’t resist. Those we will label as “office use” since they are still durable enough to use as an office chair. Our “gym use” styles are great for doing planks, pikes and sit-ups. However, we don’t recommend standing on Execuballs in street shoes. That would be like putting your feet on the Chesterfield, and what would your mom say about that? 
                </AccordionPanel>
            </AccordionItem>

            <AccordionItem>
                <h2>
                <AccordionButton  _expanded={{ bg:  "rgba(109, 108, 135, 1)", color: "white" }}>
                    <Box flex="1" textAlign="left">
                    What size ball can I use?
                    </Box>
                    <AccordionIcon />
                </AccordionButton>
                </h2>
                <AccordionPanel m="1rem" textAlign="justify">
                Currently we only support one size, 65cm which is usually considered a medium sized ball. Other common stability ball sizes are 55cm and 75cm.
Please contact us if you need a cover made for a different size.
                </AccordionPanel>
            </AccordionItem>

            <AccordionItem>
                <h2>
                <AccordionButton  _expanded={{ bg:  "rgba(109, 108, 135, 1)", color: "white" }}>
                    <Box flex="1" textAlign="left">
                    Are Execuballs stability ball covers Eco-Friendly?
                    </Box>
                    <AccordionIcon />
                </AccordionButton>
                </h2>
                <AccordionPanel m="1rem" textAlign="justify">
                Currently we only support one size, 65cm which is usually considered a medium sized ball. Other common stability ball sizes are 55cm and 75cm.
Please contact us if you need a cover made for a different size.
                </AccordionPanel>
            </AccordionItem>

            <AccordionItem>
                <h2>
                <AccordionButton  _expanded={{ bg:  "rgba(109, 108, 135, 1)", color: "white" }}>
                    <Box flex="1" textAlign="left">
                    Do you accept returns?
                    </Box>
                    <AccordionIcon />
                </AccordionButton>
                </h2>
                <AccordionPanel m="1rem" textAlign="justify">
                All our stability ball covers come with a 6 month warranty. Should you have an issue during this time, we will exchange if the style is still available or replace it with another style of equal value. 
                </AccordionPanel>
            </AccordionItem>

            
            <AccordionItem>
                <h2>
                <AccordionButton  _expanded={{ bg:  "rgba(109, 108, 135, 1)", color: "white" }}>
                    <Box flex="1" textAlign="left">
                    Will you take custom orders?
                    </Box>
                    <AccordionIcon />
                </AccordionButton>
                </h2>
                <AccordionPanel m="1rem" textAlign="justify">
                <Text>
 Yes indeedy!{" "}
        <Link color="teal.500" href="/contact">
            Please contact us for more info.
        </Link>
</Text>
                </AccordionPanel>
            </AccordionItem>

            </Accordion>
            </Box>
        </Center>
    )
}
