import React, {useEffect} from 'react';
import {Box, Grid} from '@chakra-ui/react'
import {connect} from 'react-redux'
import {fetchAllProducts} from '../actions/productActions'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom';
import ProductTile from '../components/ProductTile'


// TODO style text for products
// TODO import font
const HomePage = (props)=>{

  
    useEffect(()=>{
        props.fetchAllProducts();
      },[props])


    return(    
        <Box>
            <Grid templateColumns={["repeat(2, 1fr)","repeat(3, 1fr)"]}>
            {
                props.products.map((product)=>{
                   return <ProductTile key={product.id} product={product}/>
                })
            }
            </Grid>
        </Box>)
} 

HomePage.propTypes = { 
    fetchAllProducts: PropTypes.func.isRequired,
    products: PropTypes.array.isRequired
}

const mapStateToProps = (state)=>{
   return {
        products: state.storeReducer.products
    }
}

export default connect(mapStateToProps, {fetchAllProducts})(withRouter(HomePage))
