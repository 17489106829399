import React from 'react'
import {Link} from 'react-router-dom'
import {Flex, Box, Badge, Button, Icon, useToast, Menu,
	MenuButton,
	MenuList,
	MenuItem,
	MenuItemOption,
	MenuGroup,
	MenuOptionGroup,
	MenuIcon,
	MenuCommand,
	MenuDivider, } from '@chakra-ui/react'
import { MdMenu, MdShoppingCart} from 'react-icons/md'
import {connect} from 'react-redux'
import { toggleCart} from '../actions/checkoutActions'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom';

const NavBar = (props)=> {

		const openCart=()=>{
			props.toggleCart(true);
		}

		const onMenuClick = (path)=>{
			props.history.push(path);
		}

		return (
			<Flex alignItems="center" bgGradient="linear(160deg, rgba(109, 108, 135, 1) 27%, rgba(188, 151, 177, 1) 70%)"  flexDir="row" justifyContent="space-between" padding="2rem">
			<Menu>
				<MenuButton>
				<Icon fill="white" as={MdMenu} w={30} h={30} cursor="pointer"/>
				</MenuButton>
				<MenuList>
					<MenuItem onClick={()=>onMenuClick('/')}>Home</MenuItem>
					<MenuItem onClick={()=>onMenuClick('/faq/')}>FAQ</MenuItem>
					<MenuItem onClick={()=>onMenuClick('/press/')}>Press</MenuItem>
					<MenuItem onClick={()=>onMenuClick('/contact/')}>Contact Us!</MenuItem>
				</MenuList>
				</Menu>
			<Link to="/">
			<Box cursor="pointer" w={["12rem", "18rem", "24rem"]} alignContent="center">
				<svg width="100%" height="100%" x="0" y="0" viewBox="0 0 86 16">
					<style type="text/css">
						{`.st0{fill:#CC9F53;}`}
						{`.st1{fill:#FFFFFF;}`}
					</style>
				<path className="st0" d="M12.14,10.45H7.62c-0.68,0-1.21-0.25-1.61-0.75C5.65,9.25,5.47,8.69,5.47,8.04c0-0.29,0.04-0.56,0.11-0.82
					s0.19-0.5,0.34-0.72C5.78,6.29,5.67,6.05,5.59,5.77C5.51,5.5,5.47,5.23,5.47,4.96c0-0.65,0.18-1.2,0.54-1.66
					c0.4-0.5,0.94-0.75,1.61-0.75h4.52v1.68H7.91c-0.19,0-0.34,0.07-0.45,0.22C7.35,4.58,7.29,4.75,7.29,4.94
					c0,0.19,0.05,0.35,0.16,0.49c0.11,0.14,0.26,0.2,0.46,0.2h4.23v1.67v0.05H7.67C7.55,7.41,7.46,7.5,7.39,7.62s-0.1,0.26-0.1,0.41
					c0,0.19,0.05,0.36,0.16,0.49s0.26,0.21,0.46,0.21h4.23V10.45z"></path>
				<path className="st0" d="M20.66,10.5h-2.06l-1.87-2.92c-0.03-0.07-0.09-0.1-0.17-0.1c-0.08,0-0.12,0.03-0.13,0.1l-1.91,2.92h-2.04
					c0.21-0.34,0.42-0.68,0.64-1.02c0.5-0.8,0.9-1.44,1.18-1.91c0.19-0.34,0.38-0.68,0.57-1.03L12.46,2.6h2.04l1.91,2.92
					c0.01,0.07,0.06,0.1,0.13,0.1c0.08,0,0.14-0.03,0.17-0.1l1.87-2.92h2.06l-2.34,3.95L20.66,10.5z"></path>
				<path className="st0" d="M27.76,10.45h-4.52c-0.68,0-1.21-0.25-1.61-0.75c-0.36-0.45-0.54-1.01-0.54-1.66c0-0.29,0.04-0.56,0.11-0.82
					s0.19-0.5,0.34-0.72c-0.14-0.21-0.26-0.45-0.34-0.72c-0.08-0.27-0.12-0.55-0.12-0.82c0-0.65,0.18-1.2,0.54-1.66
					c0.4-0.5,0.94-0.75,1.61-0.75h4.52v1.68h-4.23c-0.19,0-0.34,0.07-0.45,0.22c-0.11,0.14-0.17,0.31-0.17,0.51
					c0,0.19,0.05,0.35,0.16,0.49c0.11,0.14,0.26,0.2,0.46,0.2h4.23v1.67v0.05h-4.47c-0.12,0.05-0.21,0.14-0.28,0.27s-0.1,0.26-0.1,0.41
					c0,0.19,0.05,0.36,0.16,0.49s0.26,0.21,0.46,0.21h4.23V10.45z"></path>
				<path className="st0" d="M35.19,10.49H31.3c-0.93,0-1.67-0.41-2.21-1.22c-0.48-0.73-0.73-1.62-0.73-2.69c0-1.09,0.25-2.01,0.74-2.78
					c0.55-0.84,1.28-1.26,2.2-1.26h3.89v1.68h-3.6c-0.43,0-0.77,0.23-1.01,0.69c-0.22,0.41-0.33,0.92-0.33,1.53
					c0,0.62,0.11,1.16,0.34,1.6c0.25,0.49,0.59,0.73,1.01,0.73h3.6V10.49z"></path>
				<path className="st0" d="M42.62,7.64c0,0.92-0.36,1.65-1.07,2.2c-0.64,0.49-1.43,0.74-2.35,0.74c-0.91,0-1.67-0.24-2.29-0.72
					c-0.69-0.54-1.03-1.28-1.03-2.21V2.62h1.72v4.74c0,0.42,0.18,0.76,0.53,1.01c0.32,0.23,0.71,0.34,1.18,0.34
					c0.45,0,0.82-0.11,1.12-0.33c0.34-0.25,0.51-0.59,0.51-1.02V2.62h1.68V7.64z"></path>
				<path className="st0" d="M58.63,10.49h-1.88l-2.07-6.12c-0.01-0.06-0.06-0.09-0.13-0.09c-0.08,0-0.14,0.03-0.16,0.09l-2.03,6.12h-1.9
					c0.15-0.5,0.38-1.25,0.7-2.26c0.36-1.11,0.7-2.18,1.04-3.19c0.38-1.11,0.61-1.72,0.69-1.86c0.16-0.22,0.39-0.39,0.69-0.51
					s0.62-0.18,0.98-0.18c0.35,0,0.67,0.06,0.97,0.18s0.53,0.29,0.68,0.51c0.1,0.13,0.33,0.75,0.7,1.85c0.26,0.78,0.61,1.84,1.03,3.19
					C58.16,8.98,58.39,9.74,58.63,10.49z"></path>
				<path className="st0" d="M65.71,10.49h-3.17c-1,0-1.8-0.25-2.39-0.76c-0.46-0.39-0.79-0.93-0.98-1.61c-0.12-0.45-0.19-0.93-0.19-1.46
					c0-0.1,0-0.18,0.01-0.23S59,6.32,59.01,6.27V2.54h1.76v3.73c0,0.67,0.14,1.24,0.42,1.7c0.32,0.53,0.77,0.8,1.35,0.8h3.17V10.49z"></path>
				<path className="st0" d="M73.14,10.49h-3.17c-1,0-1.8-0.25-2.39-0.76c-0.46-0.39-0.79-0.93-0.98-1.61c-0.12-0.45-0.19-0.93-0.19-1.46
					c0-0.1,0-0.18,0.01-0.23s0.01-0.11,0.02-0.15V2.54h1.76v3.73c0,0.67,0.14,1.24,0.42,1.7c0.32,0.53,0.77,0.8,1.35,0.8h3.17V10.49z"></path>
				<path className="st0" d="M80.57,4.22h-4.23c-0.19,0-0.34,0.07-0.45,0.22c-0.11,0.14-0.17,0.31-0.17,0.51c0,0.19,0.05,0.35,0.16,0.49
					c0.11,0.14,0.26,0.2,0.46,0.2h2.49c0.67,0,1.2,0.25,1.59,0.76c0.36,0.46,0.53,1.01,0.53,1.67c0,0.66-0.18,1.22-0.53,1.67
					c-0.39,0.5-0.92,0.76-1.6,0.76h-4.54V8.8h4.25c0.18,0,0.33-0.08,0.44-0.23c0.1-0.14,0.15-0.31,0.15-0.51c0-0.19-0.05-0.36-0.15-0.5
					s-0.25-0.21-0.44-0.21h-2.5c-0.68,0-1.21-0.25-1.61-0.75c-0.36-0.45-0.54-1-0.54-1.66s0.18-1.2,0.53-1.65
					c0.4-0.5,0.94-0.75,1.61-0.75h4.52V4.22z"></path>
				<g>
					<path className="st0" d="M49.61,6.52c0.16,0.2,0.28,0.43,0.36,0.71s0.12,0.55,0.12,0.83c0,0.65-0.18,1.21-0.54,1.67
						c-0.4,0.51-0.94,0.77-1.61,0.77h-4.53V8.83h4.24c0.19,0,0.35-0.08,0.46-0.23c0.11-0.14,0.16-0.3,0.16-0.5
						c0-0.19-0.05-0.35-0.15-0.48C48,7.48,47.84,7.41,47.64,7.41h-4.24V5.64h4.24c0.2,0,0.36-0.07,0.47-0.22
						c0.1-0.13,0.15-0.29,0.15-0.48c0-0.19-0.05-0.36-0.15-0.5C48,4.3,47.84,4.22,47.64,4.22h-4.24V2.54h4.53
						c0.67,0,1.21,0.26,1.62,0.78c0.37,0.47,0.55,1.03,0.55,1.68c0,0.29-0.04,0.56-0.13,0.83S49.78,6.33,49.61,6.52z"></path>
					<g>
						<path className="st1" d="M6.09,11.81v2.3c0,0.14,0.07,0.17,0.18,0.17c0.04,0,0.09-0.01,0.15-0.02v0.29c-0.11,0.04-0.23,0.05-0.31,0.05
							c-0.31,0-0.43-0.17-0.43-0.38v-2.4H6.09z"></path>
						<path className="st1" d="M7.92,13.86c0,0.25,0.15,0.39,0.41,0.39c0.16,0,0.32-0.06,0.43-0.17v-1.45h0.42v1.91H8.79v-0.23H8.78
							c-0.16,0.17-0.4,0.27-0.62,0.27c-0.44,0-0.66-0.24-0.66-0.71v-1.24h0.41V13.86z"></path>
						<path className="st1" d="M11.42,13.54l0.65,1.01h-0.46l-0.45-0.7l-0.47,0.7h-0.42l0.66-0.96l-0.61-0.95h0.46l0.41,0.64l0.43-0.64h0.4
							L11.42,13.54z"></path>
						<path className="st1" d="M13.58,13.86c0,0.25,0.15,0.39,0.41,0.39c0.16,0,0.32-0.06,0.43-0.17v-1.45h0.41v1.91h-0.38v-0.23h-0.01
							c-0.16,0.17-0.4,0.27-0.62,0.27c-0.44,0-0.66-0.24-0.66-0.71v-1.24h0.42V13.86z"></path>
						<path className="st1" d="M16.55,13.25v1.3h-0.42v-1.91h0.38v0.34h0.01c0.25-0.27,0.43-0.38,0.69-0.38V13
							C16.98,13,16.74,13.09,16.55,13.25z"></path>
						<path className="st1" d="M18.42,15.33c-0.13,0-0.21-0.01-0.32-0.04v-0.35c0.12,0.05,0.2,0.06,0.31,0.06c0.24,0,0.33-0.12,0.43-0.4
							l-0.71-1.97h0.41l0.52,1.48h0.01l0.52-1.48h0.39l-0.72,1.98C19.06,15.14,18.83,15.33,18.42,15.33z"></path>
						<path className="st1" d="M23.66,14.6c-0.54,0-0.95-0.33-0.95-0.99c0-0.68,0.44-1.01,1-1.01c0.15,0,0.31,0.02,0.46,0.07V13
							c-0.14-0.05-0.29-0.08-0.43-0.08c-0.36,0-0.6,0.2-0.6,0.66c0,0.47,0.24,0.68,0.6,0.68c0.15,0,0.31-0.03,0.48-0.1v0.33
							C24.02,14.56,23.83,14.6,23.66,14.6z"></path>
						<path className="st1" d="M26.14,14.6c-0.55,0-0.93-0.41-0.93-1c0-0.59,0.39-0.99,0.95-0.99c0.58,0,0.95,0.4,0.95,1.01
							C27.11,14.19,26.71,14.6,26.14,14.6z M26.16,12.93c-0.32,0-0.53,0.26-0.53,0.66c0,0.42,0.21,0.68,0.53,0.68
							c0.31,0,0.52-0.26,0.52-0.66C26.68,13.19,26.48,12.93,26.16,12.93z"></path>
						<path className="st1" d="M28.01,12.64h0.41l0.52,1.48h0.01l0.51-1.48h0.39l-0.69,1.91H28.7L28.01,12.64z"></path>
						<path className="st1" d="M32.38,13.52v0.19h-1.22c0.01,0.38,0.23,0.57,0.6,0.57c0.21,0,0.4-0.06,0.56-0.13v0.32
							c-0.15,0.07-0.38,0.14-0.63,0.14c-0.54,0-0.95-0.32-0.95-1c0-0.58,0.32-0.99,0.86-0.99C32.12,12.6,32.38,12.96,32.38,13.52z
							M31.16,13.43H32c0-0.32-0.14-0.5-0.38-0.5C31.33,12.92,31.17,13.13,31.16,13.43z"></path>
						<path className="st1" d="M34,13.25v1.3h-0.42v-1.91h0.38v0.34h0.01c0.25-0.27,0.43-0.38,0.69-0.38V13C34.42,13,34.18,13.09,34,13.25z"></path>
						<path className="st1" d="M36.2,14.6c-0.16,0-0.36-0.03-0.54-0.1v-0.32c0.15,0.07,0.32,0.12,0.49,0.12c0.2,0,0.34-0.06,0.34-0.27
							c0-0.37-0.83-0.2-0.83-0.87c0-0.39,0.29-0.56,0.67-0.56c0.2,0,0.37,0.04,0.47,0.09V13c-0.13-0.06-0.28-0.1-0.41-0.1
							c-0.2,0-0.33,0.07-0.33,0.25c0,0.38,0.84,0.21,0.84,0.87C36.91,14.43,36.59,14.6,36.2,14.6z"></path>
						<path className="st1" d="M39.98,12.4c0-0.42,0.23-0.64,0.66-0.64c0.11,0,0.23,0.02,0.32,0.04v0.32c-0.1-0.03-0.18-0.03-0.25-0.03
							c-0.23,0-0.32,0.13-0.32,0.37v0.18h0.47v0.3h-0.47v1.61h-0.41v-1.61h-0.32v-0.3h0.32V12.4z"></path>
						<path className="st1" d="M42.69,14.6c-0.55,0-0.93-0.41-0.93-1c0-0.59,0.39-0.99,0.95-0.99c0.58,0,0.95,0.4,0.95,1.01
							C43.67,14.19,43.26,14.6,42.69,14.6z M42.72,12.93c-0.32,0-0.53,0.26-0.53,0.66c0,0.42,0.21,0.68,0.53,0.68
							c0.31,0,0.52-0.26,0.52-0.66C43.24,13.19,43.03,12.93,42.72,12.93z"></path>
						<path className="st1" d="M45.27,13.25v1.3h-0.42v-1.91h0.38v0.34h0.01c0.25-0.27,0.43-0.38,0.69-0.38V13
							C45.69,13,45.45,13.09,45.27,13.25z"></path>
						<path className="st1" d="M49.23,14.6c-0.16,0-0.36-0.03-0.54-0.1v-0.32c0.15,0.07,0.32,0.12,0.49,0.12c0.2,0,0.34-0.06,0.34-0.27
							c0-0.37-0.83-0.2-0.83-0.87c0-0.39,0.29-0.56,0.67-0.56c0.2,0,0.37,0.04,0.47,0.09V13c-0.13-0.06-0.28-0.1-0.41-0.1
							c-0.2,0-0.33,0.07-0.33,0.25c0,0.38,0.84,0.21,0.84,0.87C49.95,14.43,49.62,14.6,49.23,14.6z"></path>
						<path className="st1" d="M51.93,14.27c0.11,0,0.21-0.03,0.29-0.05v0.32c-0.08,0.02-0.24,0.05-0.41,0.05c-0.34,0-0.57-0.16-0.57-0.57
							v-1.08h-0.32v-0.3h0.32v-0.52h0.41v0.52h0.56v0.3h-0.56v1.08C51.66,14.21,51.77,14.27,51.93,14.27z"></path>
						<path className="st1" d="M54.91,14.3c0.02,0,0.05,0,0.08-0.01v0.25c-0.08,0.03-0.16,0.05-0.25,0.05c-0.16,0-0.28-0.06-0.32-0.24h0
							c-0.12,0.14-0.32,0.24-0.58,0.24c-0.39,0-0.61-0.21-0.61-0.57c0-0.41,0.27-0.62,0.76-0.62h0.4v-0.11c0-0.26-0.12-0.37-0.41-0.37
							c-0.12,0-0.29,0.02-0.54,0.07v-0.31c0.22-0.06,0.43-0.09,0.62-0.09c0.52,0,0.75,0.21,0.75,0.7v0.89
							C54.79,14.27,54.83,14.3,54.91,14.3z M53.96,14.27c0.15,0,0.29-0.04,0.41-0.15v-0.48h-0.31c-0.29,0-0.43,0.11-0.43,0.33
							C53.63,14.17,53.73,14.27,53.96,14.27z"></path>
						<path className="st1" d="M56.56,12.85L56.56,12.85c0.16-0.16,0.36-0.24,0.57-0.24c0.49,0,0.79,0.37,0.79,0.95
							c0,0.62-0.34,1.04-0.84,1.04c-0.2,0-0.4-0.08-0.56-0.22h-0.01v0.17h-0.37v-2.74h0.42V12.85z M56.99,12.92
							c-0.16,0-0.3,0.05-0.43,0.16v1.02c0.14,0.11,0.28,0.16,0.44,0.16c0.3,0,0.49-0.28,0.49-0.71C57.49,13.17,57.29,12.92,56.99,12.92z
							"></path>
						<path className="st1" d="M59.1,12.31v-0.43h0.42v0.43H59.1z M59.1,12.64h0.42v1.91H59.1V12.64z"></path>
						<path className="st1" d="M61.23,11.81v2.3c0,0.14,0.07,0.17,0.18,0.17c0.04,0,0.09-0.01,0.15-0.02v0.29
							c-0.11,0.04-0.23,0.05-0.31,0.05c-0.31,0-0.43-0.17-0.43-0.38v-2.4H61.23z"></path>
						<path className="st1" d="M62.66,12.31v-0.43h0.41v0.43H62.66z M62.66,12.64h0.41v1.91h-0.41V12.64z"></path>
						<path className="st1" d="M65.19,14.27c0.11,0,0.21-0.03,0.29-0.05v0.32c-0.08,0.02-0.24,0.05-0.41,0.05c-0.34,0-0.57-0.16-0.57-0.57
							v-1.08h-0.32v-0.3h0.32v-0.52h0.41v0.52h0.56v0.3h-0.56v1.08C64.92,14.21,65.03,14.27,65.19,14.27z"></path>
						<path className="st1" d="M66.73,15.33c-0.13,0-0.21-0.01-0.32-0.04v-0.35c0.12,0.05,0.2,0.06,0.31,0.06c0.24,0,0.33-0.12,0.43-0.4
							l-0.71-1.97h0.41l0.52,1.48h0.01l0.52-1.48h0.39l-0.72,1.98C67.37,15.14,67.14,15.33,66.73,15.33z"></path>
						<path className="st1" d="M71.55,12.85L71.55,12.85c0.16-0.16,0.36-0.24,0.57-0.24c0.49,0,0.79,0.37,0.79,0.95
							c0,0.62-0.33,1.04-0.84,1.04c-0.2,0-0.4-0.08-0.56-0.22h-0.01v0.17h-0.37v-2.74h0.41V12.85z M71.98,12.92
							c-0.16,0-0.3,0.05-0.43,0.16v1.02c0.14,0.11,0.28,0.16,0.44,0.16c0.3,0,0.49-0.28,0.49-0.71C72.48,13.17,72.28,12.92,71.98,12.92z
							"></path>
						<path className="st1" d="M75.66,14.3c0.02,0,0.05,0,0.08-0.01v0.25c-0.08,0.03-0.15,0.05-0.25,0.05c-0.16,0-0.28-0.06-0.32-0.24h0
							c-0.12,0.14-0.32,0.24-0.58,0.24c-0.39,0-0.61-0.21-0.61-0.57c0-0.41,0.27-0.62,0.76-0.62h0.4v-0.11c0-0.26-0.12-0.37-0.41-0.37
							c-0.12,0-0.29,0.02-0.54,0.07v-0.31c0.22-0.06,0.43-0.09,0.62-0.09c0.52,0,0.75,0.21,0.75,0.7v0.89
							C75.54,14.27,75.59,14.3,75.66,14.3z M74.72,14.27c0.15,0,0.29-0.04,0.41-0.15v-0.48h-0.31c-0.29,0-0.43,0.11-0.43,0.33
							C74.38,14.17,74.49,14.27,74.72,14.27z"></path>
						<path className="st1" d="M77.31,11.81v2.3c0,0.14,0.07,0.17,0.18,0.17c0.04,0,0.09-0.01,0.15-0.02v0.29
							c-0.11,0.04-0.23,0.05-0.31,0.05c-0.31,0-0.43-0.17-0.43-0.38v-2.4H77.31z"></path>
						<path className="st1" d="M79.15,11.81v2.3c0,0.14,0.07,0.17,0.18,0.17c0.04,0,0.09-0.01,0.15-0.02v0.29
							c-0.11,0.04-0.23,0.05-0.31,0.05c-0.31,0-0.43-0.17-0.43-0.38v-2.4H79.15z"></path>
						<path className="st1" d="M80.99,14.6c-0.16,0-0.36-0.03-0.54-0.1v-0.32c0.15,0.07,0.32,0.12,0.49,0.12c0.2,0,0.34-0.06,0.34-0.27
							c0-0.37-0.83-0.2-0.83-0.87c0-0.39,0.29-0.56,0.67-0.56c0.2,0,0.37,0.04,0.47,0.09V13c-0.13-0.06-0.28-0.1-0.42-0.1
							c-0.2,0-0.33,0.07-0.33,0.25c0,0.38,0.84,0.21,0.84,0.87C81.7,14.43,81.38,14.6,80.99,14.6z"></path>
					</g>
				</g>
				</svg>
			</Box>
			</Link>
			<Box>
				 <Icon cursor="pointer" as={MdShoppingCart} fill="white" w={30} h={30} onClick={()=> openCart()}/>
				 <Badge backgroundColor="#FF38BD" borderRadius="50%" hidden={props.checkout.lineItems?.length<1}>{props.checkout.lineItems?.length}</Badge>
			</Box>
		 </Flex>
		)
}


const mapStateToProps = (state)=>{
	return {
		checkout: state.checkoutReducer.checkout,
	}
 }
 
 export default connect(mapStateToProps, {toggleCart})(withRouter(NavBar));
 
