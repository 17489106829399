import {FETCH_CART, ADD_PRODUCT_TO_CART, REMOVE_PRODUCT_FROM_CART, TOGGLE_CART} from '../actions/types'


const initState = {
    checkout: {},
    cartOpen: false
}

const  checkoutReducer = (state=initState, action)=> {
    switch(action.type){
        case FETCH_CART:
            return{
                ...state,
                checkout: action.payload.checkout
            }
        case ADD_PRODUCT_TO_CART:
            return{
                ...state,
                checkout: action.payload
            }
        case REMOVE_PRODUCT_FROM_CART:
        return{
            ...state,
            checkout: action.payload
        }
        case TOGGLE_CART:
        return{
            ...state,
            cartOpen: action.payload
        }
        default:
            return state;
    }
}

export default checkoutReducer
